import React, { useEffect, useState } from "react";
import "./DetailsPage.css";
import crossIcon from "../../../src/assets/png/crossIcon.png";
import axios from "axios";
import { firestore } from "../../components/firebase";
import { addDoc, collection, refEqual } from "firebase/firestore";
import { imgstore } from "../../components/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import LoadingOverlay from "react-loading-overlay-ts";
import { useQuery } from "react-query";
import {
  fetchAllData,
  fetchUserDetails,
  fetchSingleData,
} from "../../utils/fetchData";
import { toast } from "react-toastify";

function DetailsPage({
  isOpen,
  onClose,
  detailsData,
  service,
  refetch,
  setRefreshState,
  bookId,
}) {
  console.log("detailsData detailsData detailsData", detailsData);
  //const [firebaseImg, setFirebaseImg] = useState("")
  const apiURL = process.env.REACT_APP_API_URL;
  const getFileUrl = `${window.env_url}/v1/files/`;
  const [userData, setUserData] = useState({});
  const [isRejected, setIsRejected] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [serviceName, setServiceName] = useState([]);

  console.log("details", detailsData);

  console.log("meeting type", service.type);

  //fetch data to for coach and meeting name
  console.log("id for meeting or coach", detailsData[0]);
  let bookingDataEndpoint;
  let queryDataKey;
  if (service.type === "meeting") {
    bookingDataEndpoint = `/v1/rooms/${detailsData[0].bookedRoomId}`;
    queryDataKey = "MeetingBookRequest";
    console.log("Path for meeting details", bookingDataEndpoint);
  } else if (service.type === "coach") {
    bookingDataEndpoint = `/v1/coaches/${detailsData[0].bookedCoachId}`;
    console.log("Path for coach details", bookingDataEndpoint);
    queryDataKey = "CoachBookRequest";
  }
  const [meetingName, setMeetingName] = useState("");
  const [coachName, setCoachName] = useState("");
  const { data, isLoading } = useQuery(
    [queryDataKey, bookingDataEndpoint],
    () => fetchSingleData(bookingDataEndpoint),
    {
      onSuccess: (data) => {
        if (service.type === "meeting") {
          setMeetingName(data);
          const extractedMeetingName = data?.title || "";
          const trimmedName = extractedMeetingName.split("|")[0].trim();
          setMeetingName(trimmedName);
        } else if (service.type === "coach") {
          setCoachName(data);
          const extractedCoachName = data?.title || "";
          setCoachName(extractedCoachName);
          console.log("name for details", coachName);
        }
      },
    }
  );

  console.log("data for fetching meeting or coach name", data);

  const userEndpoint = "/v1/users";
  const {} = useQuery(
    ["User", userEndpoint],
    () => fetchUserDetails(userEndpoint),
    {
      onSuccess: (data) => {
        setUserData(data);
      },
    }
  );

  console.log("userData", userData);
  useEffect(() => {
    if (detailsData[0]?.visibility === "rejected") {
      setIsRejected(true);
      setIsAccepted(true);
    } else if (detailsData[0]?.visibility === "accepted") {
      setIsRejected(true);
      setIsAccepted(true);
    }
  }, [detailsData]);

  //firebase img code ends

  if (!isOpen || !detailsData) {
    return null;
  }
  //
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
  //for time
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Set to false if you want 24-hour format
    };
    return date.toLocaleTimeString("en-US", options);
  };
  console.log(detailsData);
  const bookingId = detailsData.length > 0 ? detailsData[0].id : null;
  console.log("Details to be added in firebase", bookingId, detailsData);

  //  Firebase ref --firestore is a db to send text
  const refData = collection(firestore, "booking");
  const coachRefData = collection(firestore, "coachBooking")
  const uploadImage = async (imageBlob) => {
    const storageRef = ref(imgstore);
    const imageRef = ref(storageRef, v4());
    await uploadBytes(imageRef, imageBlob);
    return getDownloadURL(imageRef);
  };
  //const userImage = ref(imgstore)
  // console.log("firebase reference", ref);

  console.log("detailsData", detailsData);

  const onAcceptHandle = async () => {
    try {
      const token = localStorage.getItem("token");
      const reqBody = {
        visibility: "accepted",
      };

      const url = `${apiURL}/v1/${service.type}bookings/${bookingId}`;
      const statusResponse = await axios.patch(url, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      console.log("accept response", statusResponse);
      let imgPath;
      if (userData?.imageUrl.includes("avataaars.io")) {
        imgPath = userData?.imageUrl;
      } else {
        const responsImg = await axios.get(userData.imageUrl, {
          responseType: "blob",
        });
        console.log("responsImg", responsImg);
        const imageUrl = await uploadImage(responsImg.data);
        imgPath = imageUrl;
      }
      const dataToFirebase = {
        name: `${userData.firstname} ${userData.lastname}`,
        id: detailsData[0].userId,
        visibility: "accepted",
        message: `Your ${
          service.type === "meeting" ? "Meeting booking" : "Coach booking"
        } has been accepted`,
        createdAt: Date.now(),
        adminImage: imgPath,
      };

    
      // Send data to Firebase
      await addDoc(refData, dataToFirebase);
      console.log("Data sent to Firebase successfully", dataToFirebase);

      // Set acceptance status and refresh the page
      setIsAccepted(true);
      onClose();
      // window.location.reload();
    } catch (error) {
      console.error("Error accepting booking:", error);

      toast.warn(
        "Your meeting booking has been accepted, but there is no staff assigned to the room"
      );

      // Reuse image upload and Firebase data logic in error block
      try {
        const responsImg = await axios.get(userData.imageUrl, {
          responseType: "blob",
        });
        const imageUrl = await uploadImage(responsImg.data);

        const dataToFirebase = {
          name: `${userData.firstname} ${userData.lastname}`,
          id: detailsData[0].userId,
          visibility: "accepted",
          message: `Your ${
            service.type === "meeting" ? "Meeting booking" : "Coach booking"
          } has been accepted`,
          createdAt: Date.now(),
          adminImage: imageUrl,
        };
        await addDoc(refData, dataToFirebase);
        console.log("Data sent to Firebase successfully", dataToFirebase);
      } catch (firebaseError) {
        console.error("Error adding data to Firebase:", firebaseError);
      }
      setIsAccepted(true);
      onClose();
      refetch();
    }
  };

  // const onRejectHandle = async () => {

  //   try {
  //     const reason = prompt(
  //       "Please provide a reason for declining the booking:"
  //     );
  //     if (!reason) {
  //       return;
  //     }

  //     const token = localStorage.getItem("token");
  //     const formData = new FormData();
  //     formData.append("visibility", "rejected");
  //     console.log(formData);
  //     const url = `${apiURL}/v1/${service.type}bookings/${bookingId}`;
  //     console.log("path:", url);
  //     const statusResponse = await axios.patch(
  //       `${apiURL}/v1/${service.type}bookings/${bookingId}`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-type": "application/json",
  //         },
  //       }
  //     );
  //     const responsImg = await axios.get(userData.imageUrl, {
  //       responseType: "blob",
  //     });
  //     const imageUrl = await uploadImage(responsImg.data);

  //     const dataToFirebase = {
  //       name: userData.firstname + " " + userData.lastname,
  //       id: detailsData[0].userId,
  //       visibility: "rejected",
  //       message: `Your ${
  //         service.type === "meeting" ? "Meeting booking " : "Coach booking "
  //       }has been rejected`,
  //       createdAt: Date.now(),
  //       adminImage: imageUrl,
  //       reason: reason,
  //     };

  //     //for text storage --dataToFirebase
  //     try {
  //       await addDoc(refData, dataToFirebase);

  //       console.log("Data sent to Firebase successfully", dataToFirebase);
  //     } catch (firebaseError) {
  //       console.log("Error adding data to Firebase:", firebaseError);
  //     }
  //     setIsRejected(true);
  //     onClose();
  //     window.location.reload();
  //     refetch()
  //   } catch (error) {
  //     console.log("Error rejecting booking:", error);
  //   }
  // };

  const onRejectHandle = async () => {
    try {
      const reason = prompt(
        "Please provide a reason for declining the booking:"
      );
      if (!reason) return;

      const token = localStorage.getItem("token");
      const reqBody = {
        visibility: "rejected",
      };

      const url = `${apiURL}/v1/${service.type}bookings/${bookingId}`;
      const statusResponse = await axios.patch(url, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });

      console.log(statusResponse, "enetrered");

      // Fetch and upload image
      let imgPath;
      if (userData?.imageUrl.includes("avataaars.io")) {
        imgPath = userData?.imageUrl;
      } else {
        const responsImg = await axios.get(userData.imageUrl, {
          responseType: "blob",
        });
        console.log("responsImg", responsImg);
        const imageUrl = await uploadImage(responsImg.data);
        imgPath = imageUrl;
      }
      const dataToFirebase = {
        name: `${userData.firstname} ${userData.lastname}`,
        id: detailsData[0].userId,
        visibility: "rejected",
        message: `Your ${
          service.type === "meeting" ? "Meeting booking" : "Coach booking"
        } has been rejected`,
        createdAt: Date.now(),
        adminImage: imgPath,
        reason: reason,
      };

      // Send data to Firebase
      await addDoc(refData, dataToFirebase);
      console.log("Data sent to Firebase successfully", dataToFirebase);

      // Set rejection status and refresh the page
      setIsRejected(true);
      console.log("enetrered");
      onClose();
      window.location.reload();
      refetch();
    } catch (error) {
      console.error("Error rejecting booking:", error);
    }
  };

  const prevBookingDate = detailsData[0]?.prevBookingDate;
  

  console.log("detailsData[0]?.", detailsData[0]);

  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Loading your content...">
        <div className="detailsOverlay">
          <div className="detailsContentClass">
            <div className="detailsPageTopBarContainer">
              <div className="topBarPlaceholderDiv"></div>
              <h2>Booking Information </h2>
              <span className="closeIconClass" onClick={() => onClose()}>
                <img src={crossIcon} alt="" />
              </span>
              <div className="detailsPageCloseBtn"></div>
            </div>
            {/* title of details */}
            <div className="detailsCard">
              <p className="detailsTitle">Guest Details</p>

              <div className="detailsData">
                <div className="textName">
                  <p>Guest Name </p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {detailsData[0].user.firstname +
                      " " +
                      detailsData[0].user.lastname}
                  </p>
                </div>
              </div>
              <div className="detailsData">
                <div className="textName">
                  <p>Email id</p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {detailsData[0].user.email}
                  </p>
                </div>
              </div>

              <div className="detailsData">
                <div className="textName">
                  <p>Mobile Number</p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {detailsData[0].user.phone || "-"}
                  </p>
                </div>
              </div>
              <div className="detailsData">
                <div className="textName">
                  <p>Booking Time</p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {new Date(
                      "2000-01-01T" + detailsData[0].checkInTime
                    ).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
              </div>
            </div>

            <div className="detailsCard cardMarginClass">
              <p className="detailsTitle">Reservations Details</p>
              <div className="detailsData">
                <div className="textName">
                  <p>Service</p>
                </div>
                <div className="textDesc">
                  <p className="capsClass">
                    {" "}
                    <span>:</span>
                    {service.type || "Coach"}
                  </p>
                </div>
              </div>

              <div className="detailsData">
                <div className="textName">
                  <p>Reservation Date</p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {formatDate(detailsData[0].checkInDate)}
                  </p>
                </div>
              </div>

              <div className="detailsData">
                <div className="textName">
                  <p>Duration</p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {detailsData[0].duration}
                  </p>
                </div>
              </div>

              <div className="detailsData">
                <div className="textName">
                  <p>
                    {service.type === "meeting" ? "Room Name:" : "Coach Name:"}
                  </p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {service.type === "meeting" ? meetingName : coachName}
                  </p>
                </div>
              </div>
              {/* notes */}
              <div className="detailsData">
                <div className="textName">
                  <p>Notes</p>
                </div>
                <div className="textDesc">
                  <p>
                    {" "}
                    <span>:</span>
                    {detailsData[0].notes}
                  </p>
                </div>
              </div>
              {/* notes code ends */}
              {detailsData[0]?.isRescheduled && (
                <>
                  <div className="detailsData">
                    <div className="textName">
                      <p>Previous Booking Date</p>
                    </div>
                    <div className="textDesc">
                      <p>
                        {" "}
                        <span>:</span>
                        {prevBookingDate ? formatDate(prevBookingDate) : "Date not available"}
                      </p>
                    </div>
                  </div>

                  <div className="detailsData">
                    <div className="textName">
                      <p>Rescheduled Date</p>
                    </div>
                    <div className="textDesc">
                      <p>
                        {" "}
                        <span>:</span>
                        {formatDate(detailsData[0]?.checkInDate)}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="detailsBtnWrapper">
              <button
                onClick={onRejectHandle}
                disabled={isRejected}
                className={isRejected ? "disabledButton" : ""}
              >
                Decline
              </button>
              <button
                onClick={onAcceptHandle}
                disabled={isAccepted}
                className={isAccepted ? "disabledButton" : ""}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default DetailsPage;
