import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Tag } from "primereact/tag";
import { fetchAllData } from "../../utils/fetchData";
import { useQuery } from "react-query";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./ApprovalTable.css";
import { Dropdown } from "primereact/dropdown";
import UserDetailsDialog from "./dialog/UserDetailsDIalog";
import EditIcon from "@mui/icons-material/Edit";
import UpdateMembership from "./dialog/UpdateMembership";
import axios from "axios";
import { Button } from "@mui/material";
import UpdateUserDetails from "./dialog/UpdateUserDetails";

export default function UserApprovalTable({
  usersData,
  setUsersData,
  handleReqToggle,
  currentPage,
  itemsPerPage,
  totalPages,
  handlePrevious,
  handleNext,
  refetchUsers,
}) {
  console.log("user data list inside data table:", usersData);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    lastname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
    membership: { value: null, matchMode: FilterMatchMode.EQUALS },
    visibility: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [editUserDetails, setEditUserDetails] = useState(false);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { ...prevFilters.global, value: value },
    }));
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div
        className="flex justify-content-between"
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#3384b1",
        }}
      >
        <h3 style={{ color: "white" }}>User Onboarding Table</h3>
        <span className="p-input-icon-left global-search-wrapper">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            className="global-ip-search"
            placeholder="Global Search"
            style={{ paddingLeft: "20px" }}
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();
  console.log("user Data in data table", usersData);

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setDialogVisible(true); // Show the dialog
  };

  const handleOpenEditUser = (selectedUser) => {
    setSelectedUserForEdit(selectedUser);
    setEditUserDetails(true);
  };

  const hideDialog = () => {
    setDialogVisible(false); // Hide the dialog
    setSelectedUser(null); // Clear selected user when dialog is closed
  };
  const handleOpenEditMembership = (selectedUser) => {
    setSelectedUserForEdit(selectedUser); // Set the selected user for editing
    setEditDialogVisible(true);
  };

  const rowExpansionTemplate = (user) => {
    return (
      <div className="expanded-details">
        <div className="details">
          <p>
            <strong>First Name:</strong> {user.firstname}
          </p>
          <p>
            <strong>Last Name: </strong> {user.lastname}
          </p>
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>
            <strong>Phone:</strong> {user.phone}
          </p>
          <p>
            <strong>Status:</strong> {user.visibility}
          </p>
          <p>
            <strong>Membership:</strong> {user.membership}
          </p>
          <p>
            <strong>Address:</strong> {user.address}
          </p>
        </div>
        <div className="action-btns">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleOpenEditUser(user)}
            sx={{
              mb: 2, // Adds space below the button
              backgroundColor: "#3384b1", // Custom background color
              color: "#fff", // White text for contrast
              "&:hover": {
                backgroundColor: "#87bad3", // Darker shade on hover
              },
              fontWeight: "bold", // Bold text
              padding: "10px 0", // Increased padding for a larger button
              borderRadius: "8px", // Slightly rounded corners
              width: "64%", // Set width to 70%
              mx: "auto", // Centers the button horizontally
              display: "block", // Ensures it's a block-level element for centering
              border: "none", // Removes the border
              fontFamily: "Nunito",
              gap: 2,
            }}
          >
            Edit User
            <EditIcon
              sx={{
                fontSize: "1em",
              }}
            />
          </Button>
          {/* <Button
          variant="outlined"
          color="primary"
          onClick={() => handleOpenEditMembership(user)}
          sx={{
            mb: 2, // Adds space below the button
            backgroundColor: "#3384b1", // Custom background color
            color: "#fff", // White text for contrast
            "&:hover": {
              backgroundColor: "#87bad3", // Darker shade on hover
            },
            fontWeight: "bold", // Bold text
            padding: "10px 0", // Increased padding for a larger button
            borderRadius: "8px", // Slightly rounded corners
            width: "64%", // Set width to 70%
            mx: "auto", // Centers the button horizontally
            display: "block", // Ensures it's a block-level element for centering
            border: "none", // Removes the border
            fontFamily: "Nunito"
          }}
        >
          Update Membership
        </Button> */}
        </div>
      </div>
    );
  };

  const handleRowExpand = (event) => {
    setExpandedRows(event.data);
  };

  const membershipOptions = [
    {
      label: "Investor readiness program",
      value: "investor_readiness_program",
    },
    { label: "Private Office", value: "private_office" },
    { label: "Virtual Membership", value: "virtual_membership" },
    { label: "Cubicle", value: "cubicle" },
    { label: "Hot Desk", value: "hot_desk" },
  ];

  return (
    <div className="card">
      <DataTable
        value={usersData}
        paginator
        rows={6} // Items per page  CHNAGE LATER
        totalRecords={usersData.length} // Total records for pagination
        filters={filters}
        globalFilterFields={[
          "firstname",
          "lastname",
          "email",
          "phone",
          "membership",
        ]}
        header={header}
        filterDisplay="row"
        emptyMessage="No users found."
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="id"
        expandedRows={expandedRows}
        // scrollable
        // scrollHeight="60vh"
      >
        <Column expander style={{ width: "3em" }} />
        <Column
          field="firstname"
          header="First Name"
          filter
          filterPlaceholder="Search by first name"
        />
        <Column
          field="lastname"
          header="Last Name"
          filter
          filterPlaceholder="Search by last name"
        />
        <Column
          field="email"
          header="Email"
          filter
          filterPlaceholder="Search by email"
        />
        {/* <Column
          field="phone"
          header="Phone No."
          filter
          filterPlaceholder="Search by phone"
        /> */}
        <Column
          field="membership"
          header="Membership"
          filter
          filterElement={(options) => (
            <Dropdown
              value={filters.membership?.value}
              options={membershipOptions}
              onChange={(e) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  membership: { ...prevFilters.membership, value: e.value },
                }));
              }}
              placeholder="Select Membership"
              className="p-column-filter"
            />
          )}
        />
        <Column
          field="visibility"
          header="Action"
          body={(rowData) => (
            <Button
              variant="contained"
              color={rowData.visibility === "active" ? "error" : "success"}
              onClick={() =>
                handleReqToggle(rowData.email, rowData.id, rowData.visibility)
              }
              sx={{
                fontSize: "14px",
                width: "100px",
                fontFamily: "Nunito",
                textTransform: "capitalize",
              }}
            >
              {rowData.visibility === "active" ? "Reject" : "Accept"}
            </Button>
          )}
        />
        {/* <Column
          header="View Details"
          body={(rowData) => (
            <Button
              label="View Details"
              className="p-button-info"
              onClick={() => handleViewDetails(rowData)} // Open dialog with user details
              style={{
                fontSize: "14px",
                width: "120px",
                background: "#3384b1",
                fontFamily: "Nunito",
              }}
            />
          )}
        /> */}
      </DataTable>
      {selectedUser && (
        <UserDetailsDialog
          visible={dialogVisible}
          user={selectedUser}
          onHide={hideDialog}
          handleOpenEditMembership={() => {
            console.log("vvvvvvvvv");
            setSelectedUserForEdit(selectedUser); // Set the selected user for editing
            setEditDialogVisible(true); // Show the edit dialog
          }}
          onUpdateHide={() => setEditDialogVisible(false)}
        />
      )}
      {selectedUserForEdit && (
        <UpdateMembership
          open={editDialogVisible}
          user={selectedUserForEdit}
          onHide={() => setEditDialogVisible(false)}
          membershipOptions={membershipOptions}
          refetchUsers={refetchUsers}
          closeUserDetails={hideDialog}
        />
      )}
      {selectedUserForEdit && (
        <UpdateUserDetails
          open={editUserDetails}
          user={selectedUserForEdit}
          onHide={() => setEditUserDetails(false)}
          membershipOptions={membershipOptions}
          refetchUsers={refetchUsers}
          closeUserDetails={hideDialog}
        />
      )}
    </div>
  );
}
