import { useState, useEffect } from "react";
import "./UpdatePolicy.css";
import { ReactComponent as UploadIcon } from "../../../assets/svg/upload.svg";
import axios from "axios";
import crossIcon from "../../../assets/png/crossIcon.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToggleSwitch from "../../../components/UIComponent/ToggleSwitch";
// import { Dialog } from "primereact/dialog";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const UpdatePolicy = ({ visible, onHide, policyId, onClose }) => {
  const [fileDetails, setFileDetails] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [formDetails, setFormDetails] = useState({
    policyName: "",
    policyVersion: "",
    policyFormat: "",
    policyUploadDate: "",
    policyValidityDate: "",
    visibility: false,
  });

  function checkFileType(file) {
    const mimeType = file.type;

    switch (mimeType) {
      case "image/png":
        return "png";
      case "image/jpg":
        return "jpg";
      case "image/jpeg":
        return "jpeg";
      case "application/pdf":
        return "pdf";
      case "text/plain":
        return "txt";
      default:
        return "unknown";
    }
  }

  useEffect(() => {
    const fetchPolicyDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/policy/${policyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.body;
        console.log("file data", data);
        setFormDetails({
          policyName: data.policyName,
          policyVersion: data.policyVersion,
          policyFormat: data.policyFormat,
          visibility: data.visibility,
          policyUploadDate: data?.policyUploadDate,
          policyValidityDate: data?.policyValidityDate
        });
        setFilePreview(data?.policyFile);
      } catch (error) {
        toast.error("Error fetching policy details!", error);
      }
    };

    if (policyId) {
      fetchPolicyDetails();
    }
  }, [policyId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileDetails(file);
    } else {
      setFileDetails(null);
    }
  };

  const handleInput = (e) => {
    setFormDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputClick = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleDropdownSelect = (value) => {
    setFormDetails((prev) => ({
      ...prev,
      policyFormat: value,
    }));
    setShowDropdown(false);
  };

  const handleVisibilityChange = (checked) => {
    setFormDetails((prev) => ({
      ...prev,
      visibility: checked,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const { policyName, policyVersion, policyFormat } = formDetails;
      console.log(
        " policyName, policyVersion, policyFormat ",
        policyName,
        policyVersion,
        policyFormat,
        fileDetails
      );
      if (!policyName || !policyVersion || !policyFormat) {
        toast.error("Please fill in all required fields!");
        return;
      }
      if (fileDetails) {
        const fileType = checkFileType(fileDetails);
        if (fileType !== policyFormat.toLocaleLowerCase()) {
          toast.error("Please upload correct format");
          return;
        }
      }
      const token = localStorage.getItem("token");
      const formData = new FormData();
      if (fileDetails) {
        formData.append("file", fileDetails);
        const fileUpload = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/files`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        formDetails.policyFile = fileUpload.data.body[0]?.filename;
      }

      const updatePolicyResponse = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/policy/${policyId}`,
        formDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (updatePolicyResponse.status === 200) {
        toast.success("Policy has been successfully updated!");
        onHide(); // Close the dialog after successful update
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Error updating the policy!", error);
    }
  };

  return (
    <Dialog
      open={visible}
      onClose={onHide}
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          padding: "20px",
          borderRadius: "10px",
          position: "relative",
        },
      }}
    >
      <div
        className="closeIconContainer"
        style={{ position: "absolute", right: 20, cursor: "pointer" }}
      >
        <img
          className="closeDialog"
          src={crossIcon}
          alt="Close"
          onClick={onClose}
        />
      </div>
      <DialogContent>
        <form onSubmit={submitHandler}>
          <div className="updatePolicyFields">
            <div className="updatePolicyField one">
              <label htmlFor="policyName" className="policyLabel">
                Policy Name*
              </label>
              <input
                onChange={handleInput}
                type="text"
                name="policyName"
                id="policyName"
                placeholder="Policy Name"
                value={formDetails.policyName}
              />
            </div>
            <div className="updatePolicyField one">
              <label htmlFor="policyVersion" className="policyLabel">
                Policy Version*
              </label>
              <input
                onChange={handleInput}
                type="text"
                name="policyVersion"
                id="policyVersion"
                placeholder="Version Name"
                value={formDetails.policyVersion}
              />
            </div>
            <div className="updatePolicyField one" style={{ position: "relative" }}>
              <label htmlFor="policyFormat" className="policyLabel">
                Policy Format*
              </label>
              <input
                onChange={handleInput}
                onClick={handleInputClick}
                type="text"
                name="policyFormat"
                id="policyFormat"
                placeholder="Policy File type"
                value={formDetails.policyFormat}
                readOnly
              />
              {showDropdown && (
                <div className="dropdown">
                  <div
                    className="dropDownoption"
                    onClick={() => handleDropdownSelect("JPG")}
                  >
                    JPG
                  </div>
                  <div
                    className="dropDownoption"
                    onClick={() => handleDropdownSelect("PDF")}
                  >
                    PDF
                  </div>
                  <div
                    className="dropDownoption"
                    onClick={() => handleDropdownSelect("PNG")}
                  >
                    PNG
                  </div>
                </div>
              )}
            </div>
            <div className="updatePolicyField one">
              <label htmlFor="policyUploadDate" className="policyLabel">
                Upload Date*
              </label>
              <input
                onChange={handleInput}
                type="date"
                min={new Date().toISOString().split("T")[0]}
                name="policyUploadDate"
                id="policyUploadDate"
                placeholder="Upload Date"
                value={formDetails.policyUploadDate}
              />
            </div>
            <div className="updatePolicyField one">
              <label htmlFor="policyValidity" className="policyLabel">
                Valid until*
              </label>
              <input
                onChange={handleInput}
                type="date"
                name="policyValidity"
                id="policyValidity"
                min={new Date().toISOString().split("T")[0]}
                placeholder="Valid until"
                value={formDetails.policyValidityDate}
              />
            </div>
            <div className="updatePolicyField file">
              <label htmlFor="policyFile" className="policyLabel">
                Upload Policy*
              </label>
              <input
                type="file"
                id="updatePolicyFile"
                onChange={handleFileChange}
                accept="image/png, image/jpeg, application/pdf"
              />
              <div className="uploadPolicy">
                <span className="uploadButton">
                  <UploadIcon />
                  Upload
                </span>
              </div>
              {fileDetails && (
                <span className="uploaded-file-details">
                  {fileDetails.name}
                </span>
              )}
            </div>
            <div className="visibilityToggle">
              <label htmlFor="visibility" className="policy_toggle_label">
                Visibility
              </label>
              <div className="switchContainer">
                <ToggleSwitch
                  defaultChecked={formDetails.visibility}
                  onChange={handleVisibilityChange}
                />
              </div>
            </div>
          </div>
          <DialogActions>
            {/* <Button
              type="submit"
              sx={{
                width: "20%",
                height: "32px",
                backgroundColor: "#3384b1",
                border: "none",
                color: "white",
                padding: "2% 6%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Update
            </Button> */}
            <button className="update-policy-button">Update</button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePolicy;
