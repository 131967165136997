// UpdateMembership.js
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import CustomCloseIcon from "../../../../utils/CustomCloseIcon";

const RegisterCoach = ({ open, close, coachData, updateCoach }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleCoachRegister = async () => {
    try {
      if (!firstName || !lastName) {
        toast.error("First Name and Last name are required");
        return;
      }
      const reqBody = {
        email: coachData.email || "",
        visibility: "active",
        usertype: "coach",
        firstname: firstName,
        lastname: lastName,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/auth/coach-user-register`,
        reqBody
      );
      console.log("response", response);
      if (response.status === 200) {
        toast.success("Coach has been registered");
        updateCoach(response.data.body[0].user);
        close();
      } else {
        toast.error("Error Registering the Coach");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <h2 className="updateUserHeading">Register as User</h2>
      <CustomCloseIcon fill={"white"} onClick={close} />
      <p className="coachReg">
        Please specify the coach's first name and last name in order to register
        him as User
      </p>
      <DialogContent>
        <TextField
          label="First Name"
          fullWidth
          margin="dense"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          label="Last Name"
          fullWidth
          margin="dense"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCoachRegister}
          color="primary"
          variant="outlined"
          sx={{
            mb: 2,
            backgroundColor: "#3384b1",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#87bad3",
            },
            fontWeight: "bold",
            padding: "10px 0",
            borderRadius: "8px",
            width: "24%",
            mx: "auto",
            display: "block",
            border: "none",
            fontFamily: "Nunito",
            gap: 2,
            textTransform: "capitalize",
          }}
        >
          Register
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterCoach;
