import React, { useState, useRef, useContext, useEffect } from "react";
import TopNavBar from "../../../components/TopNavBar";
import Sidebar from "../../../components/Sidebar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  dragOverHandler,
  dropHandler,
} from "../../../utils/imageDragDropHandlers";
import { useQuery } from "react-query";
import { fetchFilters } from "../../../utils/filtersOperations";
import { ReactComponent as CloseIcon } from "../../../assets/svg/closeImg.svg";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import MyContext from "../../../utils/MyContext";
import { toast } from "react-toastify";
import { Asterisk, AsteriskText } from "../../../utils/Asterisk";
import NavigateBack from "../../../utils/NavigateBack";
import ToggleSwitch from "../../../components/UIComponent/ToggleSwitch";
import RegisterCoach from "./dialog/RegisterCoach";
const apiURL = process.env.REACT_APP_API_URL;

function EditCoach() {
  const filtersEndpoint = "skills";
  const location = useLocation();
  const { id } = useParams();
  console.log("id", id);
  const [coachData, setCoachData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [fileChange, setFileChange] = useState(false);
  const [skills, setSkills] = useState([]);
  const [updatedCoach, setUpdatedCoach] = useState(false);
  const [visibility, setVisibility] = useState(
    coachData?.associatedUser?.visibility === "active"
  );
  const [newSkills, setNewSkills] = useState(coachData.skills);
  const [options, setOptions] = useState([]);
  const animatedComponents = makeAnimated();
  const currentSkills = coachData?.skills?.split("|");
  const [openRegister, setOpenRegister] = useState(false);

  const { data, isLoading } = useQuery(
    ["skillsFilters", filtersEndpoint],
    () => fetchFilters(filtersEndpoint),
    {
      onSuccess: (data) => {
        setSkills(
          data?.map((skill) => {
            return { value: skill?.title, label: skill?.title };
          })
        );
      },
    }
  );

  useEffect(() => {
    fetchSingleCoach();
  }, [id]);

  const handleOpenRegister = () => {
    setOpenRegister(true);
  };

  const handleCloseRegister = () => {
    setOpenRegister(false);
  };
  const fetchSingleCoach = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/coaches/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("single response", response.data.body[0]);
      setCoachData(response?.data?.body[0]);
      setNewSkills(
        response?.data?.body[0]?.skills
          ? response?.data?.body[0]?.skills?.split("|")
          : []
      );

      setSelectedFile(response?.data?.body[0]?.files);
      setVisibility(response.data.visibility === "active");
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    const result = newSkills?.map((skill) => ({
      label: skill,
      value: skill,
    }));
    setOptions(result);
  }, [newSkills]);

  console.log("newSkills", newSkills, options);

  console.log("coachData", coachData);
  const handleOnInputChange = (event, additionalDetails) => {
    if (additionalDetails?.name === "skills") {
      const filters = event.map((facility) => facility.value);
      setNewSkills(filters.join("|"));
      return;
    }

    const { name, value } = event.target;
    setCoachData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleImageRemove = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setSelectedFile(file);
    setFileChange(true);
  };

  const handleCoachEdit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const imageFormData = new FormData();
      imageFormData.append("file", selectedFile);
      let imagePath;

      const _email = coachData?.email;
      const trimmedEmail = String(_email)?.trim();
      if (trimmedEmail.length === 0) {
        toast.warn("Please Enter Email");
        return;
      }
      if (fileChange && selectedFile) {
        const imageUploadResponse = await axios.post(
          `${apiURL}/v1/files/`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        imagePath = imageUploadResponse.data.body[0].filename;
        console.log("image upload status:", imageUploadResponse);
      }

      const formData = new FormData();
      console.log(coachData);
      formData.append("files", imagePath || coachData.files);
      formData.append("title", coachData.title);
      formData.append("skills", newSkills);
      //   formData.append("credits", coachData.credits);
      formData.append("description", coachData.description);
      formData.append("email", coachData.email);
      console.log(formData);
      const response = await axios.patch(
        `${apiURL}/v1/coaches/${coachData.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      navigate("/coaches");
      // window.location.reload();
      console.log("success:", response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  console.log("coachDtata.skills", coachData?.skills);

  const handleToggle = async () => {
    try {
      const token = localStorage.getItem("token");
      const endpoint =
        coachData?.associatedUser?.visibility === "active"
          ? "invalidate"
          : "validate";

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/auth/${endpoint}`,
        { email: coachData?.associatedUser?.email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      console.log("coachData response", response);
      const newVisibility = response.data.body[0][1]?.visibility;
      if (newVisibility === "active" || newVisibility === "inactive") {
        setVisibility(newVisibility === "active");
      } else {
        console.error(
          "Unexpected response format",
          response.data.body[0][1]?.visibility
        );
      }
    } catch (error) {
      console.error("Error updating visibility:", error.message);
      toast.error("Error updating visibility: " + error.message);
    }
  };

  const { contextValue, setContextValue } = useContext(MyContext);

  console.log(options, skills, "opyio");
  return (
    <>
      {!isLoading && (
        <div className="navBarWrapper">
          <Sidebar />
          <div className="contentWrapper">
            <TopNavBar />
            <div className="addNewRoomWapper">
              <NavigateBack
                fill={"#3384b1"}
                onClick={() => (window.location.href = "/coaches")}
              />

              <div className="addNewFormWrapper">
                <form
                  // action=""
                  className="addNewRoomForm"
                  onSubmit={handleCoachEdit}
                >
                  <h2>Edit Coach</h2>
                  <AsteriskText />
                  <div
                    className="fileInput"
                    onDragOver={dragOverHandler}
                    onDrop={(event) => {
                      dropHandler(event, setSelectedFile);
                      setFileChange(true);
                    }}
                  >
                    <label htmlFor="files">Select File</label>
                    <div className="fileNames" style={{ display: "flex" }}>
                      <p
                        className={
                          selectedFile ? "showFileDetails" : "hideFileDetails"
                        }
                      >
                        {selectedFile ? (
                          <>
                            {selectedFile.name || selectedFile}
                            <span
                              className="removeImage"
                              onClick={handleImageRemove}
                            >
                              <CloseIcon />
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                    <input
                      type="file"
                      name="files"
                      id="files"
                      style={{ display: "none" }}
                      accept="image/png, image/jpeg"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                  </div>
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="coachName">
                      <span>Email</span>
                      <span style={{ color: "red", paddingLeft: "4px" }}>
                        *
                      </span>
                    </label>
                    <br />
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter your Email"
                      value={coachData?.email}
                      onChange={handleOnInputChange}
                      required
                    />
                  </div>
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="coachName">
                      Coach Name
                      <Asterisk />
                    </label>
                    <br />
                    <input
                      type="text"
                      name="title"
                      placeholder="Enter Coach Name"
                      value={coachData.title}
                      onChange={handleOnInputChange}
                      required
                    />
                  </div>
                  <div className="addNewFormInputWrapper creditField">
                    <label htmlFor="credits">
                      Credits
                      <Asterisk />
                    </label>
                    <br />
                    <input
                      type="text"
                      name="credits"
                      placeholder="Enter Credits"
                      value={coachData.credits}
                      onChange={handleOnInputChange}
                      onKeyDown={(e) => {
                        if (
                          !(
                            e.key === "Backspace" ||
                            e.key === "Delete" ||
                            e.key === "ArrowLeft" ||
                            e.key === "ArrowRight" ||
                            e.key === "Tab" ||
                            (e.key >= "0" && e.key <= "9")
                          )
                        ) {
                          e.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                  {!isLoading && (
                    <div className="addNewFormInputWrapper">
                      <label htmlFor="skills">
                        Skills
                        <Asterisk />
                      </label>
                      <br />
                      {options && skills && (
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          // defaultValue={
                          //   skills.filter((skill, index) =>
                          //     newSkills?.includes(skill.value)
                          //   )
                          //   // newSkills
                          // }
                          options={skills}
                          value={options}
                          onChange={handleOnInputChange}
                          name="skills"
                          className={`react-select-container ${
                            contextValue ? "indexClass" : ""
                          }`}
                          classNamePrefix="react-select"
                          classNames={{
                            container: (state) => "react-select-container",
                          }}
                          required
                        />
                      )}
                    </div>
                  )}

                  <div className="addNewFormInputWrapper descheight">
                    <label htmlFor="description">
                      Description
                      <Asterisk />
                    </label>
                    <br />
                    <textarea
                      type="text"
                      name="description"
                      placeholder="Enter Description"
                      value={coachData.description}
                      onChange={handleOnInputChange}
                      required
                    ></textarea>
                  </div>
                  <div>
                    {coachData?.associatedUser && (
                      <div className="actionContainer">
                        <p className="label-disable">Enable</p>
                        <ToggleSwitch
                          defaultChecked={
                            coachData?.associatedUser?.visibility === "active"
                          }
                          onChange={handleToggle}
                        />
                      </div>
                    )}
                  </div>
                  <div className="addBtnWrapper">
                    <div
                      className={`regCoachBtn ${
                        coachData?.associatedUser ? "disable" : ""
                      }`}
                      onClick={handleOpenRegister}
                    >
                      Register as User
                    </div>
                    <button type="submit">Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {openRegister && (
            <RegisterCoach
              open={handleOpenRegister}
              close={handleCloseRegister}
              coachData={coachData}
              updateCoach={setUpdatedCoach}
            />
          )}
        </div>
      )}
    </>
  );
}

export default EditCoach;
