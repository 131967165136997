import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import TopNavBar from "../../components/TopNavBar";
import { ReactComponent as CreateIcon } from "../../assets/svg/create.svg";
import { ReactComponent as CardViewIcon } from "../../assets/svg/CardView.svg";
import { ReactComponent as ListViewIcon } from "../../assets/svg/ListView.svg";

import PolicyRow from "./PolicyRow"; // Adjust the import path as needed
import "./Policy.css";
import { toast } from "react-toastify";
import CreatePolicy from "./create/CreatePolicy";
import PolicyCard from "./card/PolicyCard";

const Policy = () => {
  const [policyData, setPolicyData] = useState([]);
  const [activeActionMenu, setActiveActionMenu] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isCardView, setIsCardView] = useState(false);

  const router = useNavigate();

  useEffect(() => {
    getAllPolicies();
  }, []);

  const handlePageClick = (page) => {
    setPageNumber(page);
  };

  const getAllPolicies = async () => {
    try {
      const policyResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/policy`
      );
      if (policyResponse?.data?.body) {
        setPolicyData(policyResponse.data.body);
      }
    } catch (error) {
      console.error("Failed to fetch policies", error.message);
    }
  };

  const createPolicyHandler = () => {
    // router("/createPolicy");
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDelete = async (policyId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/policy/${policyId}`
      );
      toast.success("Policy deleted successfully");
      // Update state to remove deleted policy
      setPolicyData(policyData.filter((policy) => policy.id !== policyId));
    } catch (error) {
      console.error("Failed to delete policy", error);
      toast.error("Failed to delete policy. Please try again.");
    }
  };

  const toggleActionMenu = (policyId) => {
    setActiveActionMenu((prev) => (prev === policyId ? null : policyId));
  };

  const toggleView = () => {
    setIsCardView(!isCardView);
  };

  return (
    <div className="navBarWrapper">
      <Sidebar />
      <div className="contentWrapper">
        <TopNavBar />
        <div className="policyWrapper">
          <div className="createPolicyButton">
            <button onClick={createPolicyHandler}>
              <CreateIcon />
              <span className="policyText create">Create Policy</span>
            </button>
          </div>
          <div className="conditionalViewer" onClick={toggleView}>
            {isCardView ? <ListViewIcon /> : <CardViewIcon />}
          </div>
        </div>
        {isCardView ? (
          <div className="policyGrid">
            {policyData.length > 0 ? (
              policyData
                .slice((pageNumber - 1) * 10, pageNumber * 10)
                .map((policy) => <PolicyCard key={policy.id} policy={policy} />)
            ) : (
              <div className="no_policies">No policies available</div>
            )}
          </div>
        ) : (
          <div className="policyTableWrapper">
            {policyData.length > 0 ? (
              <>
                <table className="policyTable">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Version</th>
                      <th>Status</th>
                      <th>Format</th>
                      <th>Signed</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {policyData
                      .slice((pageNumber - 1) * 10, pageNumber * 10)
                      .map((policy) => (
                        <PolicyRow
                          key={policy.id}
                          policy={policy}
                          handleDelete={() => handleDelete(policy.id)}
                          isActionMenuOpen={activeActionMenu === policy.id}
                          setIsActionMenuOpen={setActiveActionMenu}
                          toggleActionMenu={toggleActionMenu}
                        />
                      ))}
                  </tbody>
                </table>
              </>
            ) : (
              <div className="no_policies">No policies available</div>
            )}
          </div>
        )}


        <div className="paginationNumbers_policy">
          {Array(Math.ceil(policyData.length / 10))
            .fill("")
            .map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageClick(index + 1)}
                className={pageNumber === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}
        </div>
        {/* </div> */}
      </div>
      {dialogOpen && (
        <CreatePolicy open={dialogOpen} handleClose={handleClose} refetch={getAllPolicies}/>
      )}
    </div>
  );
};

export default Policy;
